<!-- =========================================================================================
    File Name: CardBasic.vue
    Description: Basic Cards
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="demo-basic-card">
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
        <vx-card>
          <div slot="no-body">
            <img :src="card_1.img" alt="content-img" class="responsive card-img-top" />
          </div>
          <h5 class="mb-2">{{ card_1.title }}</h5>
          <p class="text-grey">{{ card_1.subtitle }}</p>
          <p class="text-grey">{{ card_1.subtitle_2 }}</p>
          <button
            v-on:click="createWallArt"
            type="button"
            name="button"
            class="vs-component vs-button w-full mt-6 vs-button-null vs-button-gradient"
            style="background: linear-gradient(30deg, rgb(115, 103, 240) 0%, rgb(206, 159, 252) 100%);"
          >
            <span class="vs-button-text vs-button--text">Create</span>
            <span
              class="vs-button-linex"
              style="top: auto; bottom: -2px; background: rgb(115, 103, 240); left: 50%; transform: translate(-50%);"
            ></span>
          </button>
        </vx-card>
      </div>

      <!-- <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
        <vx-card>
          <div slot="no-body">
            <img :src="card_2.img" alt="content-img" class="responsive card-img-top" />
          </div>
          <h5 class="mb-2">{{ card_2.title }}</h5>
          <p class="text-grey">{{ card_2.subtitle }}</p>
          <p class="text-grey">{{ card_2.subtitle_2 }}</p>
          <button
            v-on:click="createCutout"
            type="button"
            name="button"
            class="vs-component vs-button w-full mt-6 vs-button-null vs-button-gradient"
            style="background: linear-gradient(30deg, rgb(115, 103, 240) 0%, rgb(206, 159, 252) 100%);"
          >

            <span class="vs-button-text vs-button--text">Create</span>
            <span
              class="vs-button-linex"
              style="top: auto; bottom: -2px; background: rgb(115, 103, 240); left: 50%; transform: translate(-50%);"
            ></span>
          </button>

        </vx-card>
      </div> -->

      <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
        <vx-card>
          <div slot="no-body">
            <img :src="card_3.img" alt="content-img" class="responsive card-img-top" />
          </div>
          <h5 class="mb-2">{{ card_3.title }}</h5>
          <p class="text-grey">{{ card_3.subtitle }}</p>
          <p class="text-grey">{{ card_3.subtitle_2 }}</p>
          <button
            v-on:click="createHologram"
            type="button"
            name="button"
            class="vs-component vs-button w-full mt-6 vs-button-null vs-button-gradient"
            style="background: linear-gradient(30deg, rgb(115, 103, 240) 0%, rgb(206, 159, 252) 100%);"
          >

            <span class="vs-button-text vs-button--text">Create</span>
            <span
              class="vs-button-linex"
              style="top: auto; bottom: -2px; background: rgb(115, 103, 240); left: 50%; transform: translate(-50%);"
            ></span>
          </button>

        </vx-card>
      </div>

            <!-- <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
        <vx-card>
          <div slot="no-body">
            <img :src="card_4.img" alt="content-img" class="responsive card-img-top" />
          </div>
          <h5 class="mb-2">{{ card_4.title }}</h5>
          <p class="text-grey">{{ card_4.subtitle }}</p>
          <p class="text-grey">{{ card_4.subtitle_2 }}</p>
          <button
            v-on:click="createLinks"
            type="button"
            name="button"
            class="vs-component vs-button w-full mt-6 vs-button-null vs-button-gradient"
            style="background: linear-gradient(30deg, rgb(115, 103, 240) 0%, rgb(206, 159, 252) 100%);"
          >

            <span class="vs-button-text vs-button--text">Create</span>
            <span
              class="vs-button-linex"
              style="top: auto; bottom: -2px; background: rgb(115, 103, 240); left: 50%; transform: translate(-50%);"
            ></span>
          </button>

        </vx-card>
      </div> -->

      
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { videoPlayer } from 'vue-video-player'
import VxTimeline from '@/components/timeline/VxTimeline.vue'
import 'video.js/dist/video-js.css'

export default {
  components: {
    VuePerfectScrollbar,
    videoPlayer,
    VxTimeline
  },
  data () {
    return {
      // card 1
      card_1: {},
      card_2: {},
      card_3: {},
      card_4: {},
      card_5: {},
      card_6: {},
      card_7: {},
      card_8: {},

      chatLog: [],
      chatMsgInput: '',

      card_9: {},

      timelineData: [
        {
          color: 'primary',
          icon: 'PlusIcon',
          title: 'New Task Added',
          desc: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          time: '25 Days Ago'
        },
        {
          color: 'warning',
          icon: 'AlertCircleIcon',
          title: 'Task Update Found',
          desc: 'Cupcake gummi bears soufflé caramels candy',
          time: '15 Days Ago'
        },
        {
          color: 'success',
          icon: 'CheckIcon',
          title: 'Task Finished',
          desc: 'Candy ice cream cake. Halvah gummi bears',
          time: '20 mins ago'
        }
      ],

      name: '',
      email: '',
      msg: '',

      playerOptions: {},
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    }
  },
  computed: {
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  mounted () {
    // const scroll_el = this.$refs.chatLogPS.$el || this.$refs.chatLogPS
    // scroll_el.scrollTop = this.$refs.chatLog.scrollHeight
  },
  created () {
    // Card 1
    // this.card_1 = {
    //     img : "",
    //     title: "Hey",
    //     subtitle : "yoyo"
    // }
    this.$http
      .get('/api/create-basic-card/1')
      .then(response => {
        this.card_1 = response.data
        console.log(response.data)
      })
      .catch(error => {
        console.log(error)
      })

    // Card 2
    this.$http
      .get('/api/create-basic-card/2')
      .then(response => {
        this.card_2 = response.data
      })
      .catch(error => {
        console.log(error)
      })

    // Card 3
    this.$http
      .get('/api/create-basic-card/3')
      .then(response => {
        this.card_3 = response.data
      })
      .catch(error => {
        console.log(error)
      })

    // Card 4
    this.$http
      .get('/api/create-basic-card/4')
      .then(response => {
        this.card_4 = response.data
      })
      .catch(error => {
        console.log(error)
      })
      
  },
  
  methods: {
    createWallArt () {
      this.$router.push('/create-basic/wallart') 
    }, 
    createCutout () {
      this.$router.push('/create-basic/cutout') 
    }, 
    createHologram () {
      this.$router.push('/create-basic/hologram') 
    }, 
    createLinks () {
      this.$router.push('/create-basic/wallart') 
    } 
  }
}
</script>

<style lang="scss">
#demo-basic-card {
  .overlay-card {
    .vx-card__collapsible-content {
      max-height: 485px;
    }
  }

  .chat-card-log {
    height: 360px;

    .chat-sent-msg {
      background-color: #f2f4f7 !important;
    }
  }

  .card-video {
    .video-js {
      height: 370px;
    }
  }
}
</style>
